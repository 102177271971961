<template>
  <div class="info">
    <div class="info__header">
      <div class="info__title">{{data.stock_name}}</div>
      <!--<div v-if="data.advantage" class="info__advantage">
        <img src="@/images/icons/tag.svg" alt="" class="info__advantage-icon">
        ВЫГОДА до&nbsp; <span class="big">{{data.advantage}}</span> &nbsp;₽
      </div>-->
    </div>

    <ul class="info__bonuses">
      <li
          v-for="(bonus, i) of bonuses" :key="i"
          class="info__bonuses-item"
          v-html="bonus"
      ></li>
    </ul>

    <div class="info__btns">
      <button
        @click="clickHandler"
        class="info__btns-item button button-dark-blue"
      >
        УЗНАТЬ ЦЕНУ ПО АКЦИИ
      </button>
      <a
        v-if="data.folder_name !== 'mufasa'"
        href="#stock"
        class="info__btns-item button button-white"
      >
        Комплектации
      </a>
    </div>

  </div>
</template>


<script setup>
import { computed } from 'vue';

  const props = defineProps(['data']);
  const emit = defineEmits(['getCall']);

  const bonuses = [
      `Выгода до <b>${props.data.advantage} ₽</b>`,
      `Бонус за трейд-ин <b>${props.data.trade_in} ₽</b>`,
      `<b>Охранный комплекс</b> в комплекте`,
      `<b>Постановка на учёт</b> в ГИБДД в подарок`,
  ].map( item => `<p>${item}</p>`);

  /*const mufasa_bonuses = [
    `Цена от <b>${props.data.price} ₽</b>`,
    `<b>Уже в продаже</b>`,
    `<b>Охранный комплекс</b> в комплекте`,
    `<b>Постановка на учёт</b> в ГИБДД в подарок`,
  ].map( item => `<p>${item}</p>`);

  const bonuses = computed(() => props.data.folder_name === 'mufasa'
    ? mufasa_bonuses
    : general_bonuses
  );*/

  const clickHandler = () => emit('getCall', { type: 'sale', form: 'car' });
</script>


<style lang="scss" scoped>
  @use '@/styles/constants.scss' as *;

  $blue: #00366B;

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #111;
    background-color: #ffffff;
    padding: 25px;

    &__header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__title {
      font-size: 43px;
      font-weight: bold;
      padding-right: 0.6em;
    }

    &__advantage {
      display: flex;
      align-items: flex-end;
      font-size: 22px;
      font-weight: bold;
      color: $blue;

      .big {
        font-size: 1.2em;
      }

      &-icon {
        align-self: center;
        height: 1.3em;
        padding-right: 0.5em;
      }
    }

    &__bonuses {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      font-size: 19px;
      margin: 1em 0;

      &-item {
        display: flex;
        align-items: center;
        width: 48%;
        padding-left: 1.5em;
        margin-right: 2%;
        margin-bottom: 1em;
        background-image: url('../../../images/icons/angle-arrow.svg');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 0.7em;

        :deep(b) {
          color: $blue;
          white-space: nowrap;
        }
      }
    }

    &__btns {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &-item {
        width: 48%;
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        border: 1px solid $blue;
      }
    }
  }

  @media (max-width: 1200px) {
    .info {

      &__title {
        font-size: 30px;
      }

      &__advantage {
        font-size: 18px;
      }

      &__bonuses {
        &-item {
          font-size: 18px;
        }
      }

      &__btns {
        gap: 10px;

        &-item {
          flex-grow: 1;
          width: auto;
          font-size: 15px;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .info {
      padding: 15px 20px;

      &__advantage {
      }

      &__bonuses {
        margin-bottom: 0.1em;

        &-item {
          width: 100%;
          font-size: 16px;
          margin-bottom: 0.3em;
        }
      }

      &__btns {
        gap: 0px;
        flex-wrap: wrap;

        &-item {
          width: 100%;
          margin-top: 0.5em;
        }
      }
    }
  }

  @media (max-width: 740px) {
    .info {

      &__header {
        display: none;
      }

      &__bonuses {
        &-item {
          font-size: 14px;
          margin-bottom: 1em;
        }
      }

      &__btns {
        &-item {
          font-size: 14px;
        }
      }
    }
  }

</style>