<template>
  <div class="car">

    <div class="car__header">
      <div class="car__title">{{carItem.stock_name}}</div>
      <div class="car__advantage">
        <img src="@/images/icons/tag.svg" alt="" class="car__advantage-icon">
        ВЫГОДА до {{carItem.advantage}} ₽
      </div>
    </div>

    <div class="car__wrapper">
      <car-info
          :data="carItem"
          @getCall="$emit('getCall')"
          class="car__info"
      />

      <car-slider :imgs="carItem.gallery" class="car__slider" />
    </div>

  </div>
</template>


<script>
  import CarInfo from "./components/car-info.vue";
  import CarSlider from "./components/car-slider.vue"

  export default {
    props: ["carItem"],
    components: {
      CarInfo, CarSlider
    },
  }
</script>


<style lang="scss" scoped>
  @use '@/styles/constants.scss' as *;

  .car {
    &__header {
      display: none;
    }

    &__wrapper {
      display: flex;
    }

    &__info {
      width: 55%;
    }

    &__slider {
      width: 45%;
      max-width: 600px;
      min-width: 0;
      min-height: 100%;
      height: 350px;
    }
  }

  @media (max-width: 1400px) {
    .car {
      &__slider {
        height: auto;
      }
    }
  }

  @media (max-width: 1000px) {
    .car {
      &__info {
        width: 300px;
      }

      &__slider {
        flex-grow: 1;
      }
    }
  }

  @media (max-width: 740px) {
    .car {
      background-color: #ffffff;
      margin: 20px 10px;
      padding: 20px 0;

      &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 15px;
        padding: 0 10px;
      }

      &__title {
        font-size: calcFontSize(18, 30, 320, 740);
        font-weight: bold;
        padding-right: 1em;
      }

      &__advantage {
        display: flex;
        align-items: flex-end;
        font-size: calcFontSize(14, 20, 320, 740);
        font-weight: bold;
        color: #00366B;

        &-icon {
          align-self: center;
          height: 1.2em;
          padding-right: 0.5em;
        }
      }

      &__wrapper {
        flex-direction: column;
      }

      &__info {
        width: 100%;
        order: 2;
      }

      &__slider {
        width: 100%;
        max-width: none;
        height: 60vw;
      }
    }
  }

</style>