<template>
  <section id="models" class="cars">
    <div class="container">

      <h2 class="cars__title">Модельный ряд Hyundai</h2>

<!--      <div class="cars__selects">-->
<!--        <button class="cars__selects-item button cars__selects-item&#45;&#45;active">Все</button>-->
<!--        <button class="cars__selects-item button">Городские</button>-->
<!--        <button class="cars__selects-item button">Кроссоверы</button>-->
<!--        <button class="cars__selects-item button">Микроавтобусы</button>-->
<!--      </div>-->

      <car-item
        v-for="car_item in cars"
        :key="car_item.main_title"
        :carItem="car_item"
        @getCall="getCall({type: 'sale', form:'car'})"
        class="cars__item"
      ></car-item>

    </div>
  </section>
</template>

<script>
import Mixins from "../common/mixin";
import CarItem from "./block-car/block-car.vue";
import CarsInfo from "../common/cars-info.js";

export default {
  components: { CarItem },
  mixins: [Mixins],
  data() {
    return {
      cars: CarsInfo.CARS,
      mobile: false,
      showAll: false,
    };
  },
  mounted() {
    this.mobile = window.innerWidth <= 800;
    window.addEventListener("resize", () => {
      this.mobile = window.innerWidth <= 800;
    });
  },
};
</script>

<style lang="sass" scoped>

.cars
    background-color: #E6E3E3
    padding-top: 100px
    padding-bottom: 70px

    &__title
      font-size: 47px
      text-transform: uppercase
      text-align: center

    &__selects
      display: flex
      justify-content: center
      gap: 10px
      padding: 20px
      border-bottom: 1px solid #00366B

      &-item
        font-size: 16px
        font-weight: normal
        text-transform: uppercase
        background-color: transparent

        &--active
          color: #ffffff
          background-color: #00366B

    &__item
      margin-top: 50px

    &__button
      display: block
      margin: 0 auto
      margin-bottom: 80px

@media screen and (max-width: 1431px)
  .cars__inner
    margin: 0px

@media screen and (max-width: 740px)
  .cars
    padding-top: 50px

    &__title
      font-size: calc(2.7vw + 12px)

    &__item
      margin-top: 20px

</style>
